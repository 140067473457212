export default {
  colors: {
    blue: {
      '50': '#EBF0F9',
      '100': '#C8D4EF',
      '200': '#A5B9E4',
      '300': '#819DDA',
      '400': '#5E81CF',
      '500': '#3A66C5',
      '600': '#2F519D',
      '700': '#233D76',
      '800': '#17294F',
      '900': '#0C1427',
    },
    'blue.darker': {
      '50': '#E5EFFF',
      '100': '#B8D3FF',
      '200': '#8AB7FF',
      '300': '#5C9BFF',
      '400': '#2E7FFF',
      '500': '#00317E',
      '600': '#004FCC',
      '700': '#003C99',
      '800': '#002866',
      '900': '#001433',
    },
    cyan: {
      '50': '#EAF9FA',
      '100': '#C5EEF1',
      '200': '#A0E2E8',
      '300': '#7BD7E0',
      '400': '#56CCD7',
      '500': '#30BCC9',
      '600': '#279AA5',
      '700': '#1D747C',
      '800': '#144D52',
      '900': '#0A2729',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    green: {
      '50': '#EBFAF5',
      '100': '#C6F1E4',
      '200': '#A1E8D2',
      '300': '#7CDFC1',
      '400': '#57D5AF',
      '500': '#1A6951',
      '600': '#28A47E',
      '700': '#1E7B5F',
      '800': '#14523F',
      '900': '#0A2920',
    },
    yellow: {
      '50': '#FFF9E5',
      '100': '#FFEDB8',
      '200': '#FFE18A',
      '300': '#FFD55C',
      '400': '#FFCA2E',
      '500': '#FFBE00',
      '600': '#CC9800',
      '700': '#997200',
      '800': '#664C00',
      '900': '#332600',
    },
    red: {
      '50': '#FFF5F5',
      '100': '#FED7D7',
      '200': '#FEB2B2',
      '300': '#FC8181',
      '400': '#F56565',
      '500': '#E53E3E',
      '600': '#C53030',
      '700': '#9B2C2C',
      '800': '#822727',
      '900': '#63171B',
    },
    orange: {
      '50': '#FFFAF0',
      '100': '#FEEBC8',
      '200': '#FBD38D',
      '300': '#F6AD55',
      '400': '#ED8936',
      '500': '#DD6B20',
      '600': '#C05621',
      '700': '#9C4221',
      '800': '#7B341E',
      '900': '#652B19',
    },
  },
};
