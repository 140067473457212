import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.5/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.5/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\"],\"subsets\":[\"latin\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/pkg-onboarding/user-webapp/public/icons/logo/logo.png");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/pkg-onboarding/user-webapp/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/pkg-onboarding/user-webapp/src/components/Sentry/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/pkg-ui/design-system/src/chakra.ts");
